import React from 'react';
import LayoutIT from '../components/layoutLightIT';
import SEO from '../components/seo';
import Contact from '../containers/contactIT';

type ContactPageProps = {};

const ContactPage: React.FunctionComponent<ContactPageProps> = () => {
  return (
    <LayoutIT>
      <SEO
        title="Contatta la Galleria Sifrein"
        description="Modulo di contatto della galleria Sifrein di Parigi"
      />

      <Contact />
    </LayoutIT>
  );
};

export default ContactPage;
